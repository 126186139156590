<template>
    <div id="users">
        <el-breadcrumb separator="/" style="padding-left:10px;padding-bottom:10px;font-size:12px;">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>软件管理</el-breadcrumb-item>
            <el-breadcrumb-item>软件信息</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- 用户列表卡片区 -->
        <el-card class="box-card">
            <el-form :inline="true" :model="queryMap" label-width="100px" size="small">
                <el-form-item label="软件名称">
                    <el-input clearable
                              v-model="queryMap.name"
                              placeholder="请输入软件名称"
                    ></el-input>
                </el-form-item>
                <el-form-item label="是否可试用">
                    <el-select v-model="queryMap.status" clearable placeholder="请选择">
                        <el-option
                                v-for="item in options"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item style="margin-left:50px;">
                    <el-button type="warning" @click="reset" icon="el-icon-refresh">重置</el-button>
                    <el-button type="primary" @click="getSoftwares" icon="el-icon-search">查询</el-button>
                    <el-button
                            type="success"
                            icon="el-icon-plus"
                            @click="add"
                    >添加
                    </el-button>
                </el-form-item>
            </el-form>

            <el-table
                    :data="softwareList"
                    border style="width: 100%;"
                    row-key="id"
                    :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
                    @cell-click="trialCountList">
                <el-table-column type="expand">
                    <template slot-scope="scope">
                        <el-table size="mini" border class="table_move" :data="scope.row.softwareVersionList">
                            <el-table-column width="48" label="No" :index="indexMethod" type="index"></el-table-column>
                            <el-table-column label="软件版本" prop="version" width="155"></el-table-column>
                            <el-table-column label="发布日" prop="publishTime" width="152"></el-table-column>
                            <el-table-column label="软件大小(byte)" prop="softwareSize" width="152"></el-table-column>
                            <el-table-column label="tar包" width="190">
                                <template slot-scope="scope">
                                    <a @click="download(scope.row)" download>
                                        {{scope.row.tarName}}
                                    </a>
                                </template>
                            </el-table-column>
                            <el-table-column label="操作" width="300">
                                <template slot-scope="scope">
                                    <el-button size="small" type="text" icon="el-icon-view"
                                               @click="getSoftwareVersionDetail(scope.row.id)">详细
                                    </el-button>
                                    <el-button size="small" type="text" icon="el-icon-edit-outline"
                                               @click="getSoftwareVersion(scope.row.id)">编辑
                                    </el-button>
                                    <el-button type="text" size="small" icon="el-icon-delete"
                                               @click="removeSoftwareVersion(scope.row.id)">删除
                                    </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </template>
                </el-table-column>
                <el-table-column label="软件名称" prop="name" width="152"></el-table-column>
                <el-table-column width="100" label="是否可试用" prop="status">
                    <template slot-scope="scope">
                        <el-tag type="success" v-if="scope.row.status==0">是</el-tag>
                        <el-tag type="warning" v-if="scope.row.status==1">否</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="试用期(日)" width="100" prop="probation"></el-table-column>
                <el-table-column label="最新发布日" width="110">
                    <template slot-scope="scope">
                        <span v-if="scope.row.softwareVersionList[0]!=null">{{scope.row.softwareVersionList[0].publishTime}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="最新版本">
                    <template slot-scope="scope">
                        <span v-if="scope.row.softwareVersionList[0]!=null">{{scope.row.softwareVersionList[0].version}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="总试用授权数" prop="trialCount"></el-table-column>
                <el-table-column label="总正式授权数" prop="officialCount"></el-table-column>
                <el-table-column label="当前授权数" prop="licenseCount" width="100"></el-table-column>
                <el-table-column label="操作" width="426">
                    <template slot-scope="scope">
                        <el-button size="small" type="primary" icon="el-icon-view"
                                   @click="getSoftwareInfo(scope.row.id)">详细
                        </el-button>
                        <el-button size="small" type="primary" icon="el-icon-edit-outline"
                                   @click="getSoftware(scope.row.id)">编辑
                        </el-button>
                        <el-button type="primary" size="small" icon="el-icon-plus"
                                   @click="addVersion(scope.row.id)">添加软件版本
                        </el-button>
                        <el-button type="danger" size="small" icon="el-icon-delete"
                                   @click="removeSoftware(scope.row.id)">删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <el-pagination
                    style="margin-top:10px;"
                    background
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="queryMap.currentPage"
                    :page-sizes="[10, 20, 30]"
                    :page-size="queryMap.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total"
            ></el-pagination>

            <el-drawer
                    :title="countTitle"
                    :visible.sync="drawerTrialCount"
                    direction="rtl"
                    size="50%">
                <el-table :data="countList">
                    <el-table-column property="factoryName" label="工厂名称" width="150"></el-table-column>
                    <el-table-column property="ukeyId" label="ukey" width="200"></el-table-column>
                </el-table>
                <!-- 分页 -->
                <el-pagination
                        style="margin-top:10px;"
                        background
                        @size-change="handleSizeCountChange"
                        @current-change="handleCurrentCountChange"
                        :current-page="countMap.currentPage"
                        :page-sizes="[10, 20, 30]"
                        :page-size="countMap.pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="countTotal"
                ></el-pagination>
            </el-drawer>

            <!-- 添加对话框 -->
            <el-dialog :title="dialogTitle" class="class_dialog_hospital" :visible.sync="dialogFormVisible"
                       width="100%;" @close="closeDialog">
                <el-card class="box-card">
                    <!-- 表单 -->
                    <el-form
                            :model="addForm"
                            ref="addFormRef"
                            :rules="addFormRules"
                            label-width="160px">
                        <el-form-item label="软件名称" prop="name">
                            <el-input v-model="addForm.name" maxlength="50" placeholder="请输入软件名称"></el-input>
                        </el-form-item>
                        <el-form-item label="软件key" prop="key">
                            <el-input v-model="addForm.key"
                                      placeholder="请输入软件key" maxlength="50"></el-input>
                        </el-form-item>
                        <el-form-item label="是否可试用" prop="status">
                            <el-radio-group v-model="addForm.status">
                                <el-radio :label="0">是</el-radio>
                                <el-radio :label="1">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="试用期(日)" prop="probation" v-if="addForm.status==0">
                            <el-input v-model="addForm.probation" oninput="value=value.replace(/[^\d]/g,'')"
                                      placeholder="请输入试用期(日)" maxlength="50"></el-input>
                        </el-form-item>
                        <el-form-item label="简介" prop="introduction">
                            <el-input v-model="addForm.introduction" type="textarea"
                                      placeholder="请输入简介" maxlength="50"></el-input>
                        </el-form-item>
                        <el-form-item label="详细说明" prop="description">
                            <quill-editor
                                    class="ql-editor"
                                    v-model="addForm.description"
                                    ref="myQuillEditor"
                                    :options="editorOption">
                            </quill-editor>
                        </el-form-item>
                    </el-form>
                </el-card>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="closeDialog">取 消</el-button>
                    <el-button
                            type="primary"
                            @click="confirm"
                            :disabled="btnDisabled">确 定</el-button>
                </span>
            </el-dialog>

            <!-- 添加对话框 -->
            <el-dialog class="class_dialog_hospital" element-loading-text="上传中" element-loading-spinner="el-icon-loading"
                       v-loading="loading" :title="addDialogTitle" :visible.sync="addDialogFormVisible"
                       width="100%;"
                       @close="closeDialogVersion">
                <el-card class="box-card">
                    <!-- 表单 -->
                    <el-form
                            :model="addForm"
                            ref="form"
                            :rules="addFormRulesVersion"
                            label-width="160px">
                        <el-form-item label="版本号" prop="version">
                            <el-input v-model="addForm.version" maxlength="50" placeholder="请输入版本号"></el-input>
                        </el-form-item>
                        <el-form-item label="软件大小(byte)" prop="softwareSize">
                            <el-input v-model="addForm.softwareSize" :disabled="true"
                                      onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                                      placeholder="上传tar文件,自动获取软件大小(byte)" maxlength="50"></el-input>
                        </el-form-item>
                        <el-form-item label="发布日" prop="publishTime">
                            <el-date-picker
                                    v-model="addForm.publishTime"
                                    type="date"
                                    value-format="yyyy-MM-dd"
                                    format="yyyy-MM-dd"
                                    placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="更新说明" prop="explain">
                            <el-input v-model="addForm.explain" type="textarea" placeholder="请输入更新说明"
                                      maxlength="500" :autosize="{ minRows: 5, maxRows: 10}"></el-input>
                        </el-form-item>
                        <el-form-item label="tar文件" prop="tarFile">
                            <div class="upload-from" id="itxst">
                                <el-upload
                                        ref="upload"
                                        action=''
                                        accept=".tar"
                                        :auto-upload="false"
                                        :file-list.sync="tarFile"
                                        name="file"
                                        :limit="1"
                                        :on-change="(file, tarFile) => {getFile(file, tarFile)}"
                                        :on-remove="handleUploadRemove">
                                    <el-button size="small" type="primary">选择tar文件上传上传</el-button>
                                    <div slot="tip" class="el-upload__tip">只能上传一个tar文件</div>
                                </el-upload>
                            </div>
                        </el-form-item>
                    </el-form>
                </el-card>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="closeDialogVersion">取 消</el-button>
                    <el-button
                            type="primary"
                            @click="confirmVersion"
                            :disabled="btnDisabled">确 定</el-button>
                </span>

            </el-dialog>

            <!-- 添加对话框 -->
            <el-dialog class="class_dialog_hospital" title="详细" :visible.sync="viewDialogFormVisible" width="100%;">
                <el-card class="box-card">
                    <el-form
                            :model="addForm"
                            :label-position="labelPosition"
                            ref="addFormRef"
                            label-width="140px">
                        <el-form-item label="软件名称:">
                            <span>{{addForm.name}}</span>
                        </el-form-item>
                        <el-form-item label="软件key:">
                            <span>{{addForm.key}}</span>
                        </el-form-item>
                        <el-form-item label="是否可试用:">
                            <span>{{addForm.status===0?"是":"否"}}</span>
                        </el-form-item>
                        <el-form-item label="试用期(日):" v-if="addForm.status===0">
                            <span>{{addForm.probation}}</span>
                        </el-form-item>
                        <el-form-item label="简介:">
                            <span>{{addForm.introduction}}</span>
                        </el-form-item>
                        <el-form-item label="更新说明:">
                            <div v-html="addForm.description"
                                 style="border: 1px solid #DCDFE6; border-radius: 9px;padding: 10px;"></div>
                        </el-form-item>
                    </el-form>
                </el-card>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="closeSoftwareDetail">关闭</el-button>
                </span>
            </el-dialog>

            <!-- 添加对话框 -->
            <el-dialog class="class_dialog_hospital" :title="detailDialogTitle" :visible.sync="detailDialogFormVisible"
                       width="100%;">
                <el-card class="box-card">
                    <el-form
                            :model="addForm"
                            :label-position="labelPosition"
                            ref="addFormRef"
                            label-width="140px">
                        <el-form-item label="软件版本:">
                            <span>{{addForm.version}}</span>
                        </el-form-item>
                        <el-form-item label="发布日:">
                            <span>{{addForm.publishTime}}</span>
                        </el-form-item>
                        <el-form-item label="软件大小(byte):">
                            <span>{{addForm.softwareSize}}</span>
                        </el-form-item>
                        <el-form-item label="更新说明:">
                            <div v-html="addForm.explain"></div>
                        </el-form-item>
                    </el-form>
                </el-card>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="closeDetail">关闭</el-button>
                </span>
            </el-dialog>
        </el-card>
    </div>
</template>
<script>
    import {quillEditor} from 'vue-quill-editor'

    import 'quill/dist/quill.core.css'
    import 'quill/dist/quill.snow.css'
    import 'quill/dist/quill.bubble.css'
    import axios from "axios";

    export default {
        components: {
            quillEditor,
        },
        data() {
            return {
                editorOption: {},
                drawerTrialCount: false,
                options: [
                    {id: null, name: ""},
                    {id: 0, name: "是"},
                    {id: 1, name: "否"}
                ],
                loading: false,
                queryMap: {
                    currentPage: 1,
                    pageSize: 10,
                },
                countMap: {
                    currentPage: 1,
                    pageSize: 10,
                },
                countTitle: "",
                countTotal: null,
                publishDate: [],
                tarFile: [],
                curFileList: [],
                delFileList: [],
                resTarFile: [],
                addFormRulesVersion: {
                    version: [
                        {required: true, message: "请输入版本号", trigger: "blue"}
                    ],
                    softwareSize: [
                        {required: true, message: "请输入软件大小", trigger: "blue"}
                    ],
                    publishTime: [
                        {required: true, message: "请选择发布时间", trigger: "blue"}
                    ]
                },
                drawer: false,
                softwareList: [],
                total: null,
                addForm: {},
                dialogFormVisible: false,
                dialogTitle: "",
                dialogType: "add",
                addDialogFormVisible: false,
                addDialogTitle: "",
                addDialogType: "add",
                addFormRules: {
                    name: [
                        {required: true, message: "请输入软件名称", trigger: "blue"}
                    ],
                    status: [
                        {required: true, message: "请选择是否可试用", trigger: "blue"}
                    ],
                    probation: [
                        {required: true, message: "请输入试用期", trigger: "blue"}
                    ],
                    key: [
                        {required: true, message: "请输入软件key", trigger: "blue"}
                    ],
                    introduction: [
                        {required: true, message: "请输入简介", trigger: "blue"}
                    ]
                },
                btnDisabled: false,
                softwareVersionList: [],
                viewDialogFormVisible: false,
                countList: [],
                detailDialogFormVisible: false,
                detailDialogTitle: '',
                labelPosition: "right"
            };
        },
        methods: {
            /**
             * 关闭软件版本详细
             */
            closeDetail() {
                this.addForm = {}
                this.detailDialogFormVisible = false
            },

            /**
             * 关闭软件详细
             */
            closeSoftwareDetail() {
                this.viewDialogFormVisible = false
                this.addForm = {}
            },

            /**
             * 下载文件
             */
            async download(row) {
                const res = await this.$confirm(
                    "此操作将下载该软件tar, 是否继续?",
                    "提示",
                    {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning"
                    }
                )
                if (res === "confirm") {
                    const res = axios
                        .request({
                            url: "software/download",
                            method: "post",
                            params: {url: row.tarFile},
                            responseType: "blob"
                        })
                        .then(res => {
                            const data = res.data;
                            let url = window.URL.createObjectURL(data); // 将二进制文件转化为可访问的url
                            const a = document.createElement("a");
                            document.body.appendChild(a);
                            a.href = url;
                            a.download = row.tarName;
                            a.click();
                            window.URL.revokeObjectURL(url);
                        });
                }
            },

            /**
             * 统计个数列表
             */
            async trialCountList(row, column, cell, event) {
                let params = {
                    id: row.id,
                    currentPage: this.countMap.currentPage,
                    pageSize: this.countMap.pageSize
                }

                if (column.label === "总试用授权数") {
                    this.drawerTrialCount = true
                    this.countTitle = "总试用授权数列表"

                    const {data: res} = await this.$http.get("/software/trialCountList", {params: params});
                    this.countList = res.data.list
                    this.countTotal = res.data.total

                } else if (column.label === "总正式授权数") {
                    this.drawerTrialCount = true
                    this.countTitle = "总正式授权数列表"

                    const {data: res} = await this.$http.get("/software/officialCountList", {params: params});
                    this.countList = res.data.list
                    this.countTotal = res.data.total

                } else if (column.label === "当前授权数") {
                    this.drawerTrialCount = true
                    this.countTitle = "当前授权数列表"

                    const {data: res} = await this.$http.get("/software/licenseCountList", {params: params});
                    this.countList = res.data.list
                    this.countTotal = res.data.total
                }
            },

            /**
             * 设置富文本编辑器不可编辑
             */
            focus(event) {
                event.enable(false);
            },

            /**
             * 自定义序号
             */
            indexMethod(index) {
                return index + 1 * 1;
            },

            /**
             * 软件列表
             */
            async getSoftwares() {
                const {data: res} = await this.$http.get("/software/softwares", {
                    params: this.queryMap
                });
                this.softwareList = res.data.list;
                this.total = res.data.total;
            },

            /**
             * 打开添加弹框
             */
            add() {
                this.dialogFormVisible = true
                this.dialogTitle = "添加软件"
                this.dialogType = "add"
            },

            /**
             * 打开软件版本弹框
             */
            addVersion(softwareId) {
                this.addDialogFormVisible = true
                this.addDialogTitle = "添加软件版本"
                this.addDialogType = "add"
                this.tarFile = []
                this.addForm.softwareId = softwareId

            },

            /**
             * 检查文件大小
             */
            checkFileSize(file, fileList) {
                const isSize = file.size / 1024 / 1024; // 文件大小
                let fileName = file.name;
                if (this.fileSize && isSize > this.fileSize) {
                    for (let i = 0; i < fileList.length; i++) {
                        if (fileName == fileList[i].name) {
                            fileList.splice(i, 1);
                            break;
                        }
                    }
                    return false;
                }
                return true;
            },

            /**
             * 软件tar选择
             */
            getFile(file, imageList) {
                this.addForm.softwareSize = file.size
                this.tarFile.push(file)
            },

            /**
             * 软件tar删除
             */
            handleUploadRemove(file) {
                if (this.curFileList.indexOf(file.filePath) != -1) {
                    this.delFileList.push(file.filePath);
                } else {
                    this.tarFile = [];
                }
            },

            /**
             * 操作软件版本
             */
            confirmVersion() {
                if (this.addDialogType === "add") {
                    this.addSoftwareVersion()
                } else {
                    this.updateSoftwareVersion()
                }
            },

            /**
             * 软件版本添加
             */
            async addSoftwareVersion() {
                this.btnDisabled = true
                this.$refs.form.validate(async valid => {
                    if (!valid) {
                        setTimeout(() => {
                            this.btnDisabled = false
                        }, 1000);
                        return;
                    } else {
                        this.loading = true;
                        let formdata = new FormData();

                        if (this.tarFile == null || this.tarFile == undefined || this.tarFile == "") {
                            setTimeout(() => {
                                this.btnDisabled = false
                            }, 1000);
                            return this.$message.error("请选择tar文件");

                        } else {
                            for (let i = 0; i < this.tarFile.length; i++) {
                                formdata.append("tarFileList", this.tarFile[i].raw);
                            }
                        }
                        formdata.append("softwareId", this.addForm.softwareId);
                        formdata.append("version", this.addForm.version);
                        formdata.append("softwareSize", this.addForm.softwareSize);
                        formdata.append("publishTime", this.addForm.publishTime);
                        if (this.addForm.explain != null) {
                            formdata.append("description", this.addForm.explain);
                        }

                        // 发送请求
                        this.$http({
                            method: 'POST',
                            data: formdata,
                            url: '/softwareVersion/softwareVersion',
                        }).then((res) => {
                                if (res.data.success) {
                                    setTimeout(() => {
                                        this.btnDisabled = false
                                    }, 1000);
                                    setTimeout(() => {
                                        this.loading = false;
                                        this.addDialogFormVisible = false
                                        this.$message.success("添加成功");
                                        this.addForm = {};
                                        this.tarFile = []
                                        this.$refs.upload.clearFiles()
                                        this.getSoftwares()
                                    }, 2000);

                                } else {
                                    this.$message.error(res.data != null ? res.data : res.data.errorMsg);
                                    setTimeout(() => {
                                        this.btnDisabled = false
                                    }, 1000);
                                }
                            }
                        )
                    }
                });
            },

            /**
             * 软件版本修改
             */
            async updateSoftwareVersion() {
                this.$refs.form.validate(async valid => {
                    if (!valid) {
                        return;
                    } else {
                        this.loading = true;
                        let formdata = new FormData();

                        if (this.tarFile == null || this.tarFile == undefined || this.tarFile == "") {
                            setTimeout(() => {
                                this.btnDisabled = false
                            }, 1000);
                            return this.$message.error("请选择tar文件");
                        } else {
                            for (let i = 0; i < this.tarFile.length; i++) {
                                if (this.tarFile[i].raw != null) {
                                    formdata.append("tarFileList", this.tarFile[i].raw);
                                }
                            }
                        }
                        if (this.delFileList != null && this.delFileList.length != 0) {
                            formdata.append("deletedFileList", this.delFileList);
                        }
                        formdata.append("softwareId", this.addForm.softwareId);
                        formdata.append("version", this.addForm.version);
                        formdata.append("softwareSize", this.addForm.softwareSize);
                        formdata.append("publishTime", this.addForm.publishTime);
                        if (this.addForm.explain != null) {
                            formdata.append("description", this.addForm.explain);
                        }

                        // 发送请求
                        this.$http({
                            method: 'PUT',
                            data: formdata,
                            url: '/softwareVersion/softwareVersion/' + this.addForm.id,
                        }).then((res) => {
                                if (res.data.success) {
                                    setTimeout(() => {
                                        this.loading = false;
                                        this.addDialogFormVisible = false
                                        this.$message.success("修改成功");
                                        this.addForm = {};
                                        this.tarFile = []
                                        this.$refs.upload.clearFiles()
                                        this.getSoftwares()
                                    }, 2000);
                                } else {
                                    this.$message.error(res.data != null ? res.data : res.data.errorMsg);
                                }
                            }
                        )
                    }
                });
            },

            /**
             * 删除版本软件
             */
            async removeSoftwareVersion(id) {
                const res = await this.$confirm(
                    "此操作将该软件删除, 是否继续?",
                    "提示",
                    {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning"
                    }
                )
                if (res === "confirm") {
                    const {data: res} = await this.$http.delete("/softwareVersion/softwareVersion/" + id);
                    if (res.success) {
                        this.$message.success("删除成功");
                        this.getSoftwares()
                    } else {
                        this.$message.error(res.data != null ? res.data : res.data.errorMsg);
                    }
                }
            },

            /**
             * 获取文件名称
             */
            getFileName(name) {
                if (name == undefined || name == "") {
                    return "";
                }
                let nameList = name.split("/");
                return nameList[nameList.length - 1];
            },

            /**
             * 软件版本回显
             */
            async getSoftwareVersion(id) {
                this.tarFile = []
                this.addDialogFormVisible = true
                this.addDialogTitle = "编辑软件版本"
                this.addDialogType = "edit"
                const {data: res} = await this.$http.get("/softwareVersion/softwareVersion/" + id);
                this.addForm = res.data;
                this.$set(this.addForm, 'explain', this.addForm.description)
                if (this.addForm.tarFile) {
                    this.tarFile.push({
                        url: this.addForm.url + this.addForm.tarFile,
                        name: this.getFileName(this.addForm.tarFile),
                        filePath: this.addForm.tarFile
                    })
                }
            },

            /**
             * 软件版本详细
             */
            async getSoftwareVersionDetail(id) {
                this.detailDialogFormVisible = true
                this.detailDialogTitle = "软件版本详细"
                const {data: res} = await this.$http.get("/softwareVersion/softwareVersion/" + id);
                this.addForm = res.data;
                this.$set(this.addForm, 'explain', this.addForm.description)
            },

            /**
             * 操作软件
             */
            confirm() {
                if (this.dialogType === "add") {
                    this.addSoftware()
                } else {
                    this.updateSoftware()
                }
            },

            /**
             * 关闭弹出框
             */
            closeDialog() {
                this.addForm = {};
                this.dialogFormVisible = false
                this.$refs.addFormRef.clearValidate();
            },

            /**
             * 关闭添加版本弹出框
             */
            closeDialogVersion() {
                this.addForm = {};
                this.addDialogFormVisible = false
                this.$refs.upload.clearFiles()
                this.$refs.form.clearValidate()
            },

            /**
             * 软件添加
             */
            async addSoftware() {
                this.btnDisabled = true
                this.$refs.addFormRef.validate(async valid => {
                    if (!valid) {
                        setTimeout(() => {
                            this.btnDisabled = false
                        }, 1000);
                        return;
                    } else {
                        const {data: res} = await this.$http.post("/software/software", this.addForm);
                        if (res.success) {
                            this.$message.success("添加成功");
                            this.dialogFormVisible = false
                            this.getSoftwares()
                            this.addForm = {}
                            setTimeout(() => {
                                this.btnDisabled = false
                            }, 1000);
                        } else {
                            this.$message.error(res.data != null ? res.data : res.data.errorMsg);
                            setTimeout(() => {
                                this.btnDisabled = false
                            }, 1000);
                        }
                    }
                });
            },

            /**
             * 软件修改
             */
            async updateSoftware() {
                this.$refs.addFormRef.validate(async valid => {
                    if (!valid) {
                        return;
                    } else {
                        const {data: res} = await this.$http.put("/software/software/" + this.addForm.id, this.addForm);
                        if (res.success) {
                            this.$message.success("更新成功");
                            this.dialogFormVisible = false
                            this.getSoftwares()
                            this.addForm = {}
                        } else {
                            this.$message.error(res.data != null ? res.data : res.data.errorMsg);
                        }
                    }
                });
            },

            /**
             * 删除软件
             */
            async removeSoftware(id) {
                const res = await this.$confirm(
                    "此操作将该软件删除, 是否继续?",
                    "提示",
                    {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning"
                    }
                )
                if (res === "confirm") {
                    const {data: res} = await this.$http.delete("/software/software/" + id);
                    if (res.success) {
                        this.$message.success("删除成功");
                        this.getSoftwares()
                    } else {
                        this.$message.error(res.data != null ? res.data : res.data.errorMsg);
                    }
                }
            },

            /**
             * 软件回显
             */
            async getSoftware(id) {
                this.dialogFormVisible = true
                this.dialogTitle = "编辑软件"
                this.dialogType = "edit"
                const {data: res} = await this.$http.get("/software/software/" + id);
                this.addForm = res.data;
            },

            /**
             * 软件详细
             */
            async getSoftwareInfo(id) {
                this.viewDialogFormVisible = true
                const {data: res} = await this.$http.get("/software/software/" + id);
                this.addForm = res.data;
            },

            /**
             * 重置
             */
            reset() {
                this.queryMap = {
                    currentPage: 1,
                    pageSize: 10
                };
                this.getSoftwares()
            },

            /**
             *  软件改变页码
             */
            handleSizeChange(newSize) {
                this.queryMap.pageSize = newSize;
                this.getSoftwares()
            },
            /**
             * 软件翻页
             */
            handleCurrentChange(current) {
                this.queryMap.currentPage = current;
                this.getSoftwares()
            },

            /**
             *  抽屉改变页码
             */
            handleSizeCountChange(newSize) {
                this.countMap.pageSize = newSize;
                this.trialCountList()
            },
            /**
             * 抽屉翻页
             */
            handleCurrentCountChange(current) {
                this.countMap.currentPage = current;
                this.trialCountList()
            },

        },
        created() {
            this.getSoftwares()
            setTimeout(() => {
                this.loading = false;
            }, 500);
        }
    };
</script>

<style>
    .quill-editor /deep/ .ql-container {
        min-height: 250px;
    }

    .ql-container {
        min-height: 260px;
    }

    a {
        text-decoration: none;
        color: blue;
        cursor: pointer
    }

    .set-content {
        cursor: default;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }

    .table_move {
        position: relative;
        left: 47px;
    }
</style>
